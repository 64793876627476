import React, { createContext, useContext } from "react";
import { notification } from "antd";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = ({ type, message, description, duration = 4.5 }) => {
    api[type]({
      message,
      description,
      duration,
      placement: "topRight",
      style: {
        backgroundColor: "#fafafa",
        border: "1px solid #d9d9d9",
        borderRadius: 8,
      },
    });
  };

  return (
    <NotificationContext.Provider value={openNotification}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
