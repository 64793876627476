import axios from "axios";

export const POSTLogin = async (formValues) => {
  try {
    let data = JSON.stringify({
      email: formValues.email,
      password: formValues.password,
    });

    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/api/User/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const response = await axios.request(config);

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    if (error.response) {
      return { status: error.response.status, data: error.response.data };
    } else if (error.request) {
      return { status: 500, message: "No se recibió respuesta del servidor" };
    } else {
      return { status: 400, message: "Error al realizar la solicitud" };
    }
  }
};
