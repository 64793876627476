import React, { useEffect, useState } from "react";
import { HashRouter as Router } from "react-router-dom";
import { GlobalContext } from "./components/context/GlobalContext";
import { ConfigProvider, Spin } from "antd";
import esES from "antd/locale/es_ES";
import "./App.css";
import {
  getDataInStorage,
  removeDataInStorage,
} from "./utils/manageLocalStorage";
import GETListarIntegraciones from "./helpers/integraciones/GETListarIntegraciones";
import verifyAccessToken from "./utils/verifyAccessToken";
import NotificationProvider from "./components/notification/OpenNotification";
import AppRoutes from "./components/routes/AppRoutes";

const App = () => {
  const [loggedUser, setLoggedUser] = useState({});
  const [allIntegrations, setAllIntegrations] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const res = await getDataInStorage("userInfo");
      if (res) {
        const tokenStatus = verifyAccessToken(res); // analiza las credenciales actuales y retorna un boolean
        if (!tokenStatus) {
          setLoggedUser(res);

          const fetchData = async () => {
            // Sale a buscar las integraciones en el caso de tener sesión abierta
            const result = await GETListarIntegraciones(res);
            setAllIntegrations(result);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          };
          fetchData();
        } else {
          setLoggedUser({});
          removeDataInStorage("userInfo");
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      } else {
        setLoggedUser({});
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    // Loading creado para mejorar experencia de uso en redirección
    return (
      <div className="loading-container">
        <Spin size="default" />
      </div>
    );
  }

  return (
    <GlobalContext.Provider
      value={{
        loggedUser,
        setLoggedUser,
        selectedIntegration,
        setSelectedIntegration,
        setAllIntegrations,
      }}
    >
      <ConfigProvider
        locale={esES}
        theme={{
          token: {
            colorPrimary: "#005080",
          },
        }}
      >
        <NotificationProvider>
          <Router>
            <AppRoutes
              loggedUser={loggedUser}
              allIntegrations={allIntegrations}
            />
          </Router>
        </NotificationProvider>
      </ConfigProvider>
    </GlobalContext.Provider>
  );
};

export default App;
