import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Tooltip,
  Typography,
  Spin,
  Empty,
} from "antd";
import { GlobalContext } from "../../../../context/GlobalContext";
import GETSucursalesPorEmpresa from "../../../../../helpers/empresas/sucursales/GETSucursalesPorEmpresa";
import { LinkOutlined, PlusOutlined } from "@ant-design/icons";
import { POSTAsociarCaja } from "../../../../../helpers/empresas/sucursales/cajas/POSTAsociarCaja";
import GETCajasDisponibles from "../../../../../helpers/empresas/sucursales/cajas/GETCajasDisponibles";

const CajasForm = ({ integrationData }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [cajasPorSucursal, setCajasPorSucursal] = useState({});
  const [cajas, setCajas] = useState([]);
  const [selectedCajas, setSelectedCajas] = useState({});
  const { loggedUser } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const dataCajas = await GETSucursalesPorEmpresa(
          loggedUser,
          loggedUser?.codEmpresa
        );

        setCajas(
          dataCajas?.map((item) => ({
            cajas: item.cajas,
            nombre: item.nombre,
            codigo: item.codigo,
          }))
        );

        // Obtener cajas disponibles para cada sucursal
        const cajasDisponiblesPorSucursal = {};
        for (const sucursal of dataCajas) {
          const dataCajasDisponibles = await GETCajasDisponibles(
            loggedUser,
            integrationData?.codigo,
            sucursal.codigo
          );
          cajasDisponiblesPorSucursal[sucursal.codigo] = dataCajasDisponibles;
        }

        console.log(cajasDisponiblesPorSucursal);
        setCajasPorSucursal(cajasDisponiblesPorSucursal);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [integrationData, loggedUser]);

  const handleCajaSelectChange = (cajaId, value) => {
    setSelectedCajas((prev) => ({
      ...prev,
      [cajaId]: value,
    }));
  };

  const handleCajaAssociate = async (caja, selectedCaja) => {
    const cajaPayload = {
      codCaja: caja.codigoERP,
      codSucursal: "SUC002",
      codEmpresa: loggedUser.codEmpresa,
      codIntegracion: "MPP",
      codInterno: selectedCaja,
    };

    await POSTAsociarCaja(cajaPayload, loggedUser);
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "70dvh",
          justifyContent: "center",
        }}
      >
        <Spin size="default" />
      </div>
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      name="configFormCajas"
    >
      {cajas && cajas.length > 0 ? (
        cajas.map((sucursal) => (
          <React.Fragment key={sucursal.codigo}>
            <Typography.Text strong style={{ margin: "5px" }}>
              {sucursal.nombre}
            </Typography.Text>
            {sucursal.cajas?.length > 0 ? (
              sucursal.cajas.map((caja) => {
                const cajaId = caja.codigoERP || caja.codCajaInternoMP;
                const isCajaLinkEnabled = Boolean(selectedCajas[cajaId]);
                const cajasDisponibles =
                  cajasPorSucursal[sucursal.codigo] || [];

                return (
                  <Row
                    key={cajaId}
                    gutter={16}
                    style={{ marginBottom: 16, marginTop: 10 }}
                  >
                    <Col span={10}>
                      <Input value={caja.nombre} placeholder="Caja" readOnly />
                    </Col>
                    <Col span={11}>
                      {caja.codCajaInternoMP ? (
                        <Select
                          value={caja.codCajaInternoMP}
                          style={{ width: "100%" }}
                          readOnly
                        >
                          <Option value={caja.codCajaInternoMP}>
                            {caja.codCajaExternoMP} - {caja.codCajaInternoMP}
                          </Option>
                        </Select>
                      ) : (
                        <Select
                          allowClear
                          placeholder="Selecciona una caja disponible"
                          style={{ width: "100%" }}
                          onChange={(value) =>
                            handleCajaSelectChange(cajaId, value)
                          }
                          value={selectedCajas[cajaId] || undefined}
                        >
                          {cajasDisponibles.map((availableCaja) => (
                            <Option
                              key={availableCaja.codCajaInternoMP}
                              value={availableCaja.codCajaInternoMP}
                            >
                              {availableCaja.nombre}
                            </Option>
                          ))}
                          <Option value="crearNueva">
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <PlusOutlined style={{ marginRight: 8 }} />
                              Nueva caja
                            </span>
                          </Option>
                        </Select>
                      )}
                    </Col>
                    <Col
                      span={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Asociar caja">
                        <LinkOutlined
                          className={isCajaLinkEnabled ? "edit-icon" : ""}
                          style={{
                            color: isCajaLinkEnabled ? "#1890ff" : "#d9d9d9",
                            cursor: isCajaLinkEnabled
                              ? "pointer"
                              : "not-allowed",
                          }}
                          onClick={() =>
                            isCajaLinkEnabled &&
                            handleCajaAssociate(caja, selectedCajas[cajaId])
                          }
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                );
              })
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography.Text style={{ color: "grey" }}>
                  No hay cajas disponibles.
                </Typography.Text>
              </div>
            )}
          </React.Fragment>
        ))
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No hay cajas para esta empresa."
        />
      )}
    </Form>
  );
};

export default CajasForm;
