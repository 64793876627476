import React, { useContext, useState } from "react";
import { Button, Divider, Dropdown, Space, Image } from "antd";
import { GlobalContext } from "../../context/GlobalContext";
import { removeDataInStorage } from "../../../utils/manageLocalStorage";
import "../index.css";
import { dynamicProfileImage } from "../../../utils/dynamicProfileImage";

const ProfileSettings = ({ children }) => {
  const [loadings, setLoadings] = useState([]);
  const { loggedUser, setLoggedUser } = useContext(GlobalContext);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 1500);
  };

  const handleLogout = () => {
    enterLoading(0);
    setTimeout(() => {
      removeDataInStorage("userInfo");
      setLoggedUser({});
    }, 1500);
  };

  return (
    <Dropdown
      placement="bottomRight"
      arrow={{
        pointAtCenter: true,
      }}
      trigger={"click"}
      dropdownRender={() => (
        <div className="profile-dropdown">
          <div className="profile-header-dropdown">
            <Image
              src={
                loggedUser.avatarUrl ||
                dynamicProfileImage(loggedUser?.codEmpresa)
              }
              width={80}
              preview={false}
            />
            {/* <span>{loggedUser.nombreEmpresa}</span> */}
          </div>
          <Divider
            style={{
              margin: "8px 0",
            }}
          />
          <Space
            style={{
              padding: "8px 0",
              display: "block",
            }}
          >
            <Button
              type="primary"
              onClick={() => handleLogout()}
              loading={loadings[0]}
              style={{
                width: "100%",
              }}
            >
              Cerrar sesión
            </Button>
          </Space>
        </div>
      )}
    >
      {children}
    </Dropdown>
  );
};

export default ProfileSettings;
