/* eslint-disable react-hooks/exhaustive-deps */
import {
  Drawer,
  Button,
  Row,
  Input,
  Col,
  Tooltip,
  Typography,
  InputNumber,
  Divider,
  Spin,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useNotification } from "../../notification/OpenNotification";
import GETListarPlanesPagoXIntegracion from "../../../helpers/integraciones/GETListarPlanesPagoXIntegracion";
import {
  MinusCircleOutlined,
  UndoOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import PUTGuardarPlanesPagoERP from "../../../helpers/integraciones/PUTGuardarPlanesPagoERP";

const drawerStyles = {
  body: {
    padding: "0 16px 16px",
  },
};

const DrawerPlanesPago = ({
  openDrawer,
  setOpenDrawer,
  selectedCodigoERP,
  setSelectedCodigoERP,
  onClose,
}) => {
  const [inputs, setInputs] = useState([]);
  const [markedForRemoval, setMarkedForRemoval] = useState([]);
  const [duplicateCodes, setDuplicateCodes] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carga

  const { selectedIntegration, loggedUser } = useContext(GlobalContext);

  const openNotification = useNotification();

  // Referencias para cada input, para manipularlo, en este caso lo utilizo para darle foco cuando se crea
  const inputRefs = useRef([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (selectedCodigoERP) {
        // Filtrar solo los códigos con codigoPPERP válido
        const filteredCodes = selectedCodigoERP?.codigoPPERP.filter(
          (code) => code.idPlanesPagoERPIntegracion
        );

        let original = [];
        if (filteredCodes.length > 0) {
          original = filteredCodes.map((code) => ({
            ...code,
            codigoERP: code.codigoPPERP,
            original: true,
          }));
        }

        // Obtener los planes de pago mediante la función GETListarPlanesPagoXIntegracion
        const resultPlanesPago = await GETListarPlanesPagoXIntegracion(
          loggedUser,
          selectedIntegration,
          selectedCodigoERP?.idMediosPagoERPIntegracion
        );

        if (resultPlanesPago) {
          // Crear un mapa con los datos de los planes de pago
          const planMap = new Map();

          resultPlanesPago.forEach((plan) => {
            // Verifica si el plan tiene cuotas definidas

            if (!planMap.has(plan.idPlanesPagoIntegracion)) {
              planMap.set(plan.idPlanesPagoIntegracion, []);
            }
            // Crea una copia del objeto plan antes de añadirlo al array
            const planCopy = {
              idPlanesPagoIntegracion: plan.idPlanesPagoIntegracion,
              nombrePlan: plan.nombrePlan,
              codigoERP: plan.codigoERP,
              coeficiente: plan.coeficiente || 1,
              descripcionERP: plan.descripcionERP || "",
              original: false,
              cantCuotas: plan.cuotas,
              tipoPlanPago: plan.tipoPlanPago,
            };

            planMap.get(plan.idPlanesPagoIntegracion).push(planCopy);
          });

          // Actualizar los inputs con los datos de los planes de pago
          const updatedInputs = Array.from(planMap.entries()).flatMap(
            ([id, plans]) => {
              const existingPlans = original.filter(
                (item) => item.idPlanesPagoIntegracion === id
              );

              // Mapeo para combinar entradas originales y nuevas
              return plans.map((plan) => {
                const existingPlan = existingPlans.find(
                  (item) => item.codigoERP === plan.codigoERP
                );
                if (existingPlan) {
                  // Combinar las llaves y datos de los planes existentes y nuevos
                  return {
                    ...existingPlan,
                    nombrePlan: plan.nombrePlan,
                    codigoERP: plan.codigoERP,
                    coeficiente: plan.coeficiente || existingPlan.coeficiente,
                    descripcionERP:
                      plan.descripcionERP || existingPlan.descripcionERP,
                    tipoPlanPago: plan.tipoPlanPago,
                    cantCuotas: plan.cantCuotas,
                  };
                } else {
                  return plan;
                }
              });
            }
          );

          // Asegurarse de incluir todos los planes configurados que están en `original`
          const allInputs = [
            ...updatedInputs,
            ...original.filter(
              (item) => !planMap.has(item.idPlanesPagoIntegracion)
            ),
          ];

          // Separar en configurados y no configurados
          const configuredPlans = allInputs.filter((item) => item.original);
          const nonConfiguredPlans = allInputs.filter((item) => !item.original);

          const comparePlans = (a, b) => {
            // Ordenar por tipoPlanPago descendente
            if (a.tipoPlanPago !== b.tipoPlanPago) {
              return b.tipoPlanPago - a.tipoPlanPago;
            }

            // Ordenar por cantCuotas ascendente
            if (a.cantCuotas !== b.cantCuotas) {
              return a.cantCuotas - b.cantCuotas;
            }

            // Ordenar por nombre ascendente
            return a.nombrePlan.localeCompare(b.nombrePlan);
          };

          // Filtrar y ordenar los planes configurados y no configurados
          const sortedConfiguredPlans = configuredPlans.sort(comparePlans);

          const sortedNonConfiguredPlans =
            nonConfiguredPlans.sort(comparePlans);

          // Combinar y establecer el estado
          const sortedInputs = [
            ...sortedConfiguredPlans,
            ...sortedNonConfiguredPlans,
          ];

          setInputs(sortedInputs);
        }
      }
      setLoading(false); // Establecer estado de carga a falso
    };

    fetchData();
  }, [loggedUser, selectedIntegration, selectedCodigoERP]);

  const handleSave = async () => {
    const filteredInputs = inputs.filter((input) => input.codigoERP !== null);

    // Agregar la llave action a cada elemento basado en add/update o delete
    const finalCodes = filteredInputs.map((input, index) => ({
      ...input,
      action: markedForRemoval.includes(index) ? 0 : 1,
    }));

    const payload = {
      idmediosPagoErp: selectedCodigoERP.idMediosPagoERPIntegracion,
      codigoIntegracion: selectedIntegration.codigo,
      codigosPPERP: finalCodes,
    };

    try {
      const response = await PUTGuardarPlanesPagoERP(loggedUser, payload);

      if (response.status === 200) {
        if (
          response.data.result &&
          Array.isArray(response.data.result) &&
          response.data.result.length > 0
        ) {
          // Extraer los mensajes de los códigos duplicados
          const resultMessages = response.data.result
            .map((item) => item.msg)
            .join(", ");

          // Mostrar una notificación de advertencia que indique conflicto de codigos
          openNotification({
            type: "warning",
            message: "Conflicto con códigos",
            description: `Detalle: ${resultMessages}`,
            duration: 2.5,
          });
        } else {
          setMarkedForRemoval([]);
          setDuplicateCodes([]);
          setInputs([]);

          // setOpenDrawer(false);
          const updatedMedioPago = {
            ...selectedCodigoERP,
            codigo: response.data.codigosPPERP, // Tomo los insertados para actualizar el registro actual en el cliente
          };
          onClose(updatedMedioPago); // Invoco función que cierra drawer y devuelve datos para actualizar registro de tabla
          openNotification({
            type: "success",
            message: "Planes de pago guardados correctamente.",
          });
        }
      } else {
        openNotification({
          type: "error",
          message: "Se produjo un error",
          duration: 1.5,
        });
      }
    } catch (error) {
      openNotification({
        type: "error",
        message: "Se produjo un error",
        description: error.message,
        duration: 1.5,
      });
    }
  };

  const handleCancel = () => {
    setOpenDrawer(false);
    setSelectedCodigoERP(null);
    setMarkedForRemoval([]);
    setInputs([]);
  };

  const isSaveDisable = () => {
    // Verificar si al menos un elemento en inputs tiene codigoERP definido y !== null
    const atLeastOneCodeDefined = inputs.some(
      (input) => input.codigoERP !== null
    );

    // Si no hay elementos en inputs, ninguno tiene codigoERP definido y !== null, o hay duplicados, retornar true para deshabilitar
    if (
      inputs.length === 0 ||
      !atLeastOneCodeDefined ||
      duplicateCodes.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleInputChange = (index, field, value) => {
    setInputs((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index
          ? {
              ...input,
              [field]: isNaN(value) && value,
            }
          : input
      )
    );
  };

  const markInputForRemoval = (index) => {
    if (inputs[index].original) {
      if (!markedForRemoval.includes(index)) {
        setMarkedForRemoval([...markedForRemoval, index]);
      } else {
        setMarkedForRemoval(markedForRemoval.filter((item) => item !== index));
      }
    } else {
      const updatedInputs = inputs.filter((_, i) => i !== index);
      setInputs(updatedInputs);
    }

    const updatedInputs = inputs.filter((_, i) => i !== index);
    const codes = updatedInputs.map((input) => input.codigoERP);
    const validCodes = codes.filter((code) => code !== null);

    const duplicates = validCodes.filter(
      (code, index) => validCodes.indexOf(code) !== index
    );

    setDuplicateCodes(duplicates);
  };

  const handleBlur = () => {
    const codes = inputs.map((input) => input.codigoERP);

    // Filtrar los códigos válidos (no nulos)
    const validCodes = codes.filter((code) => code !== null && code !== "");

    // Encontrar duplicados solo entre los códigos válidos
    const duplicates = validCodes.filter(
      (code, index) => validCodes.indexOf(code) !== index
    );

    setDuplicateCodes(duplicates);
  };

  const handleDuplicateRow = (index, input) => {
    // Crear el nuevo modelo de datos del input
    const newInput = {
      codigoERP: null,
      descripcionERP: null,
      idPlanesPagoERPIntegracion: 0,
      idPlanesPagoIntegracion: input.idPlanesPagoIntegracion,
      nombrePlan: input.nombrePlan,
      coeficiente: 1,
    };

    setInputs((prevInputs) => {
      // Insertar el nuevo input luego de la fila padre
      const updatedInputs = [
        ...prevInputs.slice(0, index + 1), // Elementos antes del índice + 1
        newInput, // Nuevo input
        ...prevInputs.slice(index + 1), // Elementos después del índice + 1
      ];

      // Retornar los inputs actualizados
      return updatedInputs;
    });

    setTimeout(() => {
      const lastIndex = index + 1; // El nuevo input estará justo después del índice marcado
      if (inputRefs.current[lastIndex]) {
        inputRefs.current[lastIndex].focus();
      }
    }, 100);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSave();
      }
    };

    if (openDrawer) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [openDrawer, handleSave]);

  return (
    <Drawer
      width={650}
      destroyOnClose
      maskClosable={false}
      styles={drawerStyles}
      title={`${selectedCodigoERP?.codigoMPERP} ${
        selectedCodigoERP?.descripcionERP
          ? `- ${selectedCodigoERP.descripcionERP}`
          : ""
      } (${selectedCodigoERP?.nombreMediosPago})`}
      open={openDrawer}
      placement="right"
      onClose={handleCancel}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={handleCancel} style={{ marginRight: 8 }}>
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            disabled={isSaveDisable()}
          >
            Guardar
          </Button>
        </div>
      }
    >
      {loading ? (
        <div className="loading-wrapper">
          <Spin size="small" />
        </div>
      ) : (
        <>
          <Row
            gutter={24}
            style={{
              marginBottom: "12px",
              backgroundColor: "white",
              position: "sticky",
              top: 0,
              zIndex: 9999,
              padding: "12px",
              boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Col span={6}>
              <Typography.Text
                strong
                style={{ paddingLeft: "0px", fontSize: "12px" }}
              >
                Plan
              </Typography.Text>
            </Col>
            <Col
              span={selectedIntegration?.usaCoeficiente ? 4 : 6}
              style={{ paddingLeft: "0px" }}
            >
              <Typography.Text strong style={{ fontSize: "12px" }}>
                Código ERP
              </Typography.Text>
            </Col>
            <Col span={selectedIntegration?.usaCoeficiente ? 6 : 8}>
              <Typography.Text
                strong
                style={{ paddingLeft: "0px", fontSize: "12px" }}
              >
                Descripción
              </Typography.Text>
            </Col>

            {selectedIntegration?.usaCoeficiente && (
              <Col span={5}>
                <Typography.Text
                  strong
                  style={{ paddingLeft: "0px", fontSize: "12px" }}
                >
                  Coeficiente
                </Typography.Text>
              </Col>
            )}

            <Col span={1}></Col>
          </Row>
          {inputs.map((input, index) => (
            <div key={index}>
              <Row gutter={24} style={{ marginBottom: 8 }}>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text>{input.nombrePlan}</Typography.Text>
                </Col>
                <Col
                  span={selectedIntegration?.usaCoeficiente ? 4 : 6}
                  style={{ paddingLeft: 0 }}
                >
                  <Input
                    ref={(el) => (inputRefs.current[index] = el)} // Asignar referencia
                    value={input.codigoERP}
                    placeholder="Código"
                    onChange={(e) =>
                      handleInputChange(index, "codigoERP", e.target.value)
                    }
                    onBlur={handleBlur}
                    style={{
                      borderColor: duplicateCodes.includes(input.codigoERP)
                        ? "red"
                        : "",
                    }}
                    disabled={markedForRemoval.includes(index)}
                  />
                </Col>

                <Col span={selectedIntegration?.usaCoeficiente ? 6 : 8}>
                  <Input
                    value={input.descripcionERP}
                    placeholder="Descripción"
                    onChange={(e) =>
                      handleInputChange(index, "descripcionERP", e.target.value)
                    }
                    disabled={markedForRemoval.includes(index)}
                  />
                </Col>

                {selectedIntegration?.usaCoeficiente && (
                  <Col span={4}>
                    <InputNumber
                      ref={(el) => (inputRefs.current[index] = el)} // Asignar referencia
                      controls={false}
                      style={{ width: "80%" }}
                      onChange={(value) =>
                        handleInputChange(index, "coeficiente", value)
                      }
                      value={input.coeficiente}
                      min={0}
                      max={10}
                      // disabled={true}
                      disabled={markedForRemoval.includes(index)}
                      readOnly
                    />
                  </Col>
                )}

                <Col
                  span={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: 0,
                  }}
                >
                  <Tooltip title={"Duplicar plan de pago"}>
                    <AppstoreAddOutlined
                      onClick={() => handleDuplicateRow(index, input)}
                    />
                  </Tooltip>

                  {markedForRemoval.includes(index) ? (
                    <Tooltip title={"Recuperar plan de pago"}>
                      <UndoOutlined
                        onClick={() => markInputForRemoval(index)}
                        style={{ color: "green" }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={"Eliminar plan de pago"}>
                      <MinusCircleOutlined
                        onClick={() => markInputForRemoval(index)}
                        style={{ color: "red" }}
                      />
                    </Tooltip>
                  )}
                </Col>
              </Row>

              <Divider style={{ margin: "6px 0" }} />
            </div>
          ))}
        </>
      )}
    </Drawer>
  );
};

export default DrawerPlanesPago;
