import axios from "axios";

export const POSTAsociarCaja = async (dataCaja, loggedUser) => {
  try {
    const { tokenDatos } = loggedUser;

    const config = {
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/Cajas/AsociarCaja`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenDatos.accessToken}`,
      },
      data: dataCaja,
    };

    const response = await axios.request(config);

    console.log(response);

    return response.status === 200
      ? { status: response.status, data: response.data }
      : {
          status: response.status,
          message: "Error inesperado al asociar la caja",
        };
  } catch (error) {
    // Manejo de errores
    if (error.response) {
      return { status: error.response.status, data: error.response.data };
    } else if (error.request) {
      return { status: 500, message: "No se recibió respuesta del servidor" };
    } else {
      return { status: 400, message: "Error al realizar la solicitud" };
    }
  }
};
