import { Drawer } from "antd";
import React from "react";
import TabsIntegraciones from "./tabsIntegraciones/TabsIntegraciones";

const DrawerIntegracion = ({ drawerIntegration, setDrawerIntegration }) => {
  const onClose = () => {
    setDrawerIntegration(null);
  };

  const drawerStyles = {
    body: {
      padding: "0 16px 16px",
    },
  };

  return (
    <>
      <Drawer
        width={520}
        destroyOnClose
        maskClosable={false}
        title={drawerIntegration?.integration?.nombre}
        placement={"right"}
        onClose={onClose}
        open={drawerIntegration?.visible}
        key={"drawerIntegracion"}
        styles={drawerStyles}
      >
        <TabsIntegraciones drawerIntegration={drawerIntegration} />
      </Drawer>
    </>
  );
};

export default DrawerIntegracion;
