import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Tooltip,
  Typography,
  Divider,
  Empty,
  Spin,
  Tabs,
} from "antd";
import { GlobalContext } from "../../../../context/GlobalContext";
import GETSucursalesDisponibles from "../../../../../helpers/empresas/sucursales/GETSucursalesDisponibles";
import GETSucursalesPorEmpresa from "../../../../../helpers/empresas/sucursales/GETSucursalesPorEmpresa";
import { POSTAsociarSucursal } from "../../../../../helpers/empresas/sucursales/POSTAsociarSucursal";
import { LinkOutlined, PlusOutlined } from "@ant-design/icons";

const SucursalesForm = ({ integrationData }) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [sucursalesDisponibles, setSucursalesDisponibles] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const { loggedUser } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [selectedBranches, setSelectedBranches] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Establece loading en true al inicio

      try {
        const dataSucursalesDisponibles = await GETSucursalesDisponibles(
          loggedUser,
          integrationData?.codigo
        );
        setSucursalesDisponibles(dataSucursalesDisponibles);

        const dataSucursales = await GETSucursalesPorEmpresa(
          loggedUser,
          loggedUser?.codEmpresa
        );

        setSucursales(dataSucursales);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Establece loading en false una vez que los datos se han cargado
      }
    };

    fetchData();
  }, [integrationData, loggedUser]);

  const handleAssociate = async (sucursal, selectedBranch) => {
    const payload = {
      codSucursal: sucursal.codigo,
      codEmpresa: loggedUser.codEmpresa,
      codIntegracion: integrationData.codigo,
      codInterno: selectedBranch,
    };
    await POSTAsociarSucursal(payload, loggedUser);
  };

  const handleSelectChange = (sucursalId, value) => {
    setSelectedBranches((prev) => ({
      ...prev,
      [sucursalId]: value,
    }));
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "70dvh",
          justifyContent: "center",
        }}
      >
        <Spin size="default" />
      </div>
    );
  }

  return (
    <Form form={form} layout="vertical" autoComplete="off" name="configForm">
      {sucursales && sucursales.length > 0 ? (
        sucursales.map((sucursal) => {
          const isLinkEnabled = selectedBranches[sucursal.codigo];

          return (
            <Row key={sucursal.codigo} gutter={16} style={{ marginBottom: 16 }}>
              <Col span={10}>
                <Input
                  value={sucursal.nombre}
                  placeholder="Sucursal"
                  readOnly
                />
              </Col>
              <Col span={11}>
                {sucursal.codInternoMP ? (
                  <Select
                    value={sucursal.codInternoMP}
                    style={{ width: "100%" }}
                    readOnly
                  >
                    <Option value={sucursal.codInternoMP}>
                      {sucursal.codExternoMP} - {sucursal.codInternoMP}
                    </Option>
                  </Select>
                ) : (
                  <Select
                    allowClear
                    placeholder="Selecciona una sucursal disponible"
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      handleSelectChange(sucursal.codigo, value)
                    }
                  >
                    {sucursalesDisponibles &&
                      sucursalesDisponibles.length > 0 &&
                      sucursalesDisponibles.map((availableBranch) => (
                        <Option
                          key={availableBranch.codInternoMP}
                          value={availableBranch.codInternoMP}
                        >
                          {availableBranch.nombre}
                        </Option>
                      ))}
                    <Option value="crearNueva">
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <PlusOutlined style={{ marginRight: 8 }} />
                        Nueva sucursal
                      </span>
                    </Option>
                  </Select>
                )}
              </Col>
              <Col
                span={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Asociar sucursal">
                  <LinkOutlined
                    className={isLinkEnabled && "edit-icon"}
                    style={{
                      color: isLinkEnabled ? "#1890ff" : "#d9d9d9",
                      cursor: isLinkEnabled ? "pointer" : "not-allowed",
                    }}
                    onClick={() =>
                      isLinkEnabled &&
                      handleAssociate(
                        sucursal,
                        selectedBranches[sucursal.codigo]
                      )
                    }
                  />
                </Tooltip>
              </Col>
            </Row>
          );
        })
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No hay sucursales para esta empresa."
        />
      )}
    </Form>
  );
};

export default SucursalesForm;
