import React from "react";
import { Typography, Tabs } from "antd";
import SucursalesForm from "./SucursalesForm";
import CajasForm from "./CajasForm";
import DispositivosForm from "./DispositivosForm";

const ConfigurationTabs = ({ integrationData }) => {
  const tabItems = [
    {
      key: "1",
      label: (
        <Typography.Text style={{ margin: 0 }}>Sucursales</Typography.Text>
      ),
      children: <SucursalesForm integrationData={integrationData} />,
    },
    {
      key: "2",
      label: <Typography.Text style={{ margin: 0 }}>Cajas</Typography.Text>,
      children: <CajasForm integrationData={integrationData} />,
    },
    {
      key: "3",
      label: (
        <Typography.Text style={{ margin: 0 }}>Dispositivos</Typography.Text>
      ),
      children: <DispositivosForm integrationData={integrationData} />,
    },
  ];
  return <Tabs defaultActiveKey="1" items={tabItems} size="small" />;
};

export default ConfigurationTabs;
