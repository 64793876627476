import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import GETSucursalesPorEmpresa from "../../../../../helpers/empresas/sucursales/GETSucursalesPorEmpresa";
import GETDispositivosPorSucursal from "../../../../../helpers/empresas/sucursales/cajas/dispositivos/GETDispositivosPorSucursal";
import GETDispositivosDisponibles from "../../../../../helpers/empresas/sucursales/cajas/dispositivos/GETDispositivosDisponibles";
import { Spin, Row, Col, Typography, Select, Empty } from "antd";

const { Option } = Select;

const DispositivosForm = ({ integrationData }) => {
  const { loggedUser } = useContext(GlobalContext);
  const [sucursales, setSucursales] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        // Paso 1: Obtener las sucursales
        const sucursalesData = await GETSucursalesPorEmpresa(
          loggedUser,
          loggedUser?.codEmpresa
        );

        // Paso 2: Para cada sucursal, obtenemos las cajas y los dispositivos
        const sucursalesConCajasYDispositivos = await Promise.all(
          sucursalesData.map(async (sucursal) => {
            // Obtener las cajas para esta sucursal
            const dispositivosPorCaja = await Promise.all(
              sucursal.cajas.map(async (caja) => {
                // Obtener dispositivos para cada caja
                const dispositivos = await GETDispositivosPorSucursal(
                  loggedUser,
                  integrationData?.codigo,
                  loggedUser?.codEmpresa,
                  sucursal.codigo
                );

                // Obtener dispositivos disponibles (se debe filtrar por caja)
                const dispositivosDisponibles =
                  await GETDispositivosDisponibles(
                    loggedUser,
                    integrationData?.codigo,
                    loggedUser?.codEmpresa,
                    sucursal.codigo,
                    caja.codigoERP || caja.codCajaInternoMP
                  );

                // Filtrar dispositivos que coincidan con el código de la caja (codCaja == codigoERP)
                const dispositivosFiltrados = dispositivos
                  .map((dispositivo) => ({
                    ...dispositivo,
                    disponibles: dispositivosDisponibles.filter(
                      (disp) =>
                        disp.codDispositivo === dispositivo.codDispositivo
                    ),
                  }))
                  .filter(
                    (dispositivo) => dispositivo.codCaja === caja.codigoERP
                  );

                return {
                  ...caja,
                  dispositivos: dispositivosFiltrados, // Aquí asignamos solo los dispositivos filtrados
                };
              })
            );

            return {
              ...sucursal,
              cajas: dispositivosPorCaja,
            };
          })
        );

        console.log(sucursalesConCajasYDispositivos);
        setSucursales(sucursalesConCajasYDispositivos);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [loggedUser, integrationData]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
        }}
      >
        <Spin size="default" />
      </div>
    );
  }

  return (
    <>
      {sucursales && sucursales.length > 0 ? (
        sucursales?.map((sucursal) => (
          <React.Fragment key={sucursal.codigo}>
            <Typography.Text strong style={{ margin: "5px" }}>
              {sucursal.nombre}
            </Typography.Text>
            {sucursal?.cajas?.length > 0 ? (
              sucursal?.cajas?.map((caja) => {
                const cajaId = caja.codigoERP || caja.codCajaInternoMP;

                return (
                  <Row
                    key={cajaId}
                    gutter={16}
                    style={{ marginBottom: 16, marginTop: 10 }}
                  >
                    <Col span={10}>
                      <Typography.Text>{caja.nombre}</Typography.Text>
                    </Col>

                    {caja?.dispositivos?.length > 0 ? (
                      caja?.dispositivos?.map((dispositivo) => (
                        <Col span={24} key={dispositivo.codDispositivo}>
                          <Row gutter={16}>
                            <Col span={18}>
                              <Typography.Text strong>
                                {dispositivo.codDispositivo}
                              </Typography.Text>
                            </Col>
                          </Row>
                        </Col>
                      ))
                    ) : (
                      <Typography.Text style={{ color: "grey" }}>
                        No hay dispositivos disponibles para esta caja.
                      </Typography.Text>
                    )}
                  </Row>
                );
              })
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography.Text style={{ color: "grey" }}>
                  No hay cajas disponibles para esta sucursal.
                </Typography.Text>
              </div>
            )}
          </React.Fragment>
        ))
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No hay dispositivos para esta empresa."
        />
      )}
    </>
  );
};

export default DispositivosForm;
